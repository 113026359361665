@import '../../style/abstracts/_abstracts-dir';

:root {
    --primary-color: #48655a;
    --secondary-color: #e7e5cf;
}

.leagues-card-header {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 20px;
    text-align: center;
}

.leagues-card-header h1 {
    text-transform: capitalize;
}

.leagues-info {
    font-size: 20px;
}

.leagues-card-images {
    display: flex;
    flex-direction: row;
}

.leagues-card-images img {
    width: 640px;
}

@media screen and (max-width: 1400px) {
    .card-together {
        display: flex;
        flex-direction: row;
    }
        
    .leagues-card-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
    }

    h1 {
        font-size: 50px;
        text-align: left;
        line-height: 4.5rem;    
    }

    .leagues-info {
        font-size: 35px;
        width: fit-content;
    }

    .leagues-card-images {
        flex-direction: column;
    }
}

@media screen and (max-width: 1260px) {
    .card-together {
       flex-direction: column;
    }

    .leagues-card-header {
        padding: 2rem 0;
    }

    h1 {
        text-align: center;
    }

    .leagues-card-images img {
        width: auto;
    }
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 30px;
    }

    .leagues-info {
        font-size: 20px;
    }
}