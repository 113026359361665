@import '../../style/abstracts/_abstracts-dir';

:root {
    --color-primary: #48655a;
    --color-secondary: #e7e5cf;
    --color-tertiary: #f3f4f6;
}

.fullswing-food {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
}

.food-card {
    margin-bottom: 2;
    width: 900px; 
    background-color: var(--color-primary); 
    border-radius: 5px; 
    padding: 20px 
}

.food-header {
    color: var(--color-secondary);
    margin: 2;
    text-align: center;
    border-bottom: 1px solid var(--color-secondary);
}

.fullswing-inhouse {
    border-bottom: 1px solid var(--color-secondary);
}

.fullswing-inhouse-food h2, 
.fullswing-inhouse-beverage h2 {
    color: var(--color-secondary);
    border-bottom: 1px solid var(--color-secondary);
    width: fit-content;
}

.fullswing-inhouse-food p,
.fullswing-inhouse-beverage p {
    color: var(--color-tertiary);
    font-size: 20px;
}

.fullswing-bar-description p,
.outside-restaurants p {
    text-align: justify;
}

.fullswing-bar-description {
    font-size: 20px;
    color: var(--color-tertiary);
}

.fullswing-bar-description h2 {
    color: var(--color-secondary);
    border-bottom: 1px solid var(--color-secondary);
    width: fit-content;}

.fullswing-inhouse-image {
    background-image: url(../../images/bar.jpeg);
    height: 412px;
    opacity: .5;
}

.fullswing-inhouse-image p {
    color: var(--color-tertiary);
    font-size: 75px;
    text-align: center;
    margin: 1rem;
}

.outside-restaurants p {
    color: var(--color-secondary);
    font-size: 20px;
}

.legends-restaurant {
    display: flex;
    align-items: center;
}

li a {
    color: var(--color-secondary);
}

li {
    list-style-type: none;
    font-size: 25px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1055px) {
    .food-card {
        width: fit-content;
    }
}

@media screen and (max-width: 856px) {

    .legends-restaurant {
        display: flex;
        flex-direction: column;
    }

    ul {
        padding: 0;
    }

    li {
        text-align: center;
    }
}
