@import '../../style/abstracts/_abstracts-dir';

.page {
    align-items: center;
}

.book-now-link {
    text-decoration: none;
    color: $eggShell;
}
