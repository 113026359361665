@import '../../style/abstracts/_abstracts-dir';

.address {
  text-align: center;
  cursor: pointer;
  margin: 1rem 0;
  text-decoration: underline;
  color: var(--primary-color);
}

