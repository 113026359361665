@import '../../style/abstracts/_abstracts-dir';


.footer-address {
    .address {
        color: var(--secondary-color);
    }
}

.underlined {
    margin: 0 0 1rem 0;
    text-decoration: underline;
    color: var(--secondary-color);
    cursor: pointer;
}

h3 {
    margin: 0 0 .5rem 0;
    text-decoration: underline;
    text-align: center;
}

.footer-time {
    display: flex;
    justify-content: space-between;
}

span {
    margin-left: .5rem;
}

.MuiStack-root.directions.css-1uv00o,
.MuiStack-root.directions.css-ff85co-MuiStack-root {
    width: 200px;
}

.MuiStack-root.google-map.css-8sarns-MuiStack-root,
.MuiStack-root.google-map.css-1cr5cwi {
    padding: 0;
}
