@import './abstracts/_abstracts-dir';

#root {
  height: 100%;
  width: 100%;
}

html,
body {
  margin: 0;
  height: -webkit-fill-available;
  font-family: "Roboto", 'Quicksand', sans-serif;
  background-color: #F6F6EE;
}

.page {
  height: 580px;
  overflow: auto;
  margin: 2rem 4rem;
}

.primary {
  color: var(--primary-color);
}

.secondary {
  color: var(--secondary-color);
}

