@import '../../style/abstracts/_abstracts-dir';

#home-page {

  .address {
    margin-top: 2rem;
  }

  .google-map {
    align-items: center;
    .google-map--box {
      width: 75%;
      height: 300px;
    }
  }

  .front-door-image {
    width: 50%;
  }
}
