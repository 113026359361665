@import '../../style/abstracts/_abstracts-dir';

:root {
    --primary-color: #48655a;
    --secondary-color: #e7e5cf;
    --tertiary-color: #42544d;
}

.fullswing-image {
   width: 50%};

.golf-card-one {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
    background-color: var(--tertiary-color);
    border-radius: 5px;
}

.golf-card-two {
    background-color: var(--secondary-color);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    margin: 1rem 0 6rem 0;
}

.golf-description {
    margin: 0 0 1rem 0;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    line-height: .5rem;
}

.register {
    margin: 1rem 0;
}

.weekly-price {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    line-height: 1rem;
    color: var(--tertiary-color);
}

.weekly-price p {
   font-size: 1.4rem;
}

.weekly-price h2 {
   font-size: 5rem;
}

.date-time {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1.2rem;
    line-height: 5rem;
    color: var(--tertiary-color);
}

.date {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1rem;
}

.time {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1.2rem;
    line-height: 25px;
}

@media screen and (max-width: 1195px) {
    .register {
        line-height: 2rem;
    }

}

@media screen and (max-width: 1080px) {
    .MuiStack-root.divide-two.css-pl8gqh-MuiStack-root,
    .MuiStack-root.divide-two.css-jj2ztu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .weekly-price {
        border-bottom: solid 1px var(--tertiary-color);
    }

    .date-time {
        flex-direction: row;
    }
}

@media screen and (max-width: 935px) {
    .golf-card-one {
        width: auto;
    }

    .golf-description {
        text-align: justify;
    }

    .golf-card-two {
        margin-bottom: 4rem;
    }

    .weekly-price .junior-price {
        line-height: 4.5rem;
        font-size: 50px;
    }

    .MuiStack-root.date-time-jr.css-1xzrl5o-MuiStack-root,
    .MuiStack-root.date-time-jr.css-jysdq9 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mobile-week {
        display: flex;
        flex-direction: column;
        font-weight: 400;
    }

    .MuiStack-root.divide-one.css-pl8gqh-MuiStack-root,
    .MuiStack-root.divide-one.css-jj2ztu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .date-time {
        flex-direction: column;
        width: -webkit-fill-available;
        align-items: center;
    }

    .MuiStack-root.jr-time.css-m69qwo-MuiStack-root {
        margin: 0;
    }
}
