@import '../../style/abstracts/_abstracts-dir';

.book-now-button {
    margin-bottom: 20px;
    margin-right: 20px;
}

#rates-page {
    flex-direction: column;
    align-items: center; // Keeps everything centered horizontally
    justify-content: center; // Centers vertically in the viewport
    height: 100vh; // Ensures the page takes full viewport height
    text-align: center;

    .MuiStack-root {
        width: auto; // Adjust the width as needed, 'auto' for natural width
        justify-content: center; // Ensures vertical centering of children
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 10px 0; // Maintains vertical spacing between list items
        }
    }
}
