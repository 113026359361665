@import '../../style/abstracts/_abstracts-dir';

:root {
  --primary-color: #48655a;
  --secondary-color: #e7e5cf;
}

.jumbotron {
  background-color: var(--primary-color);
  padding: 20px 0;
}

.jumbotron-card-left, 
.jumbotron-card-right {
  width: 50%;
  display: flex;
  justify-content: center;
}

.jumbotron-card-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.jumbotron-image-left,
.jumbotron-image-right {
  width: 550px;
  height: fit-content;
}

.jumbotron-buttons a {
  font-size: 30px;
  margin: 0 1rem;
  color: var(--secondary-color);
  text-transform: uppercase;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
}

.jumbotron-buttons a:hover {
  background-color: var(--primary-color);
  color: white;
}

.tech-description {
  background-color: var(--secondary-color);
  padding: 3rem 1rem;
}

.tech-description p {
  line-height: 2;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  text-align: justify;
}

.three-images,
.MuiStack-root.three-images.css-m69qwo-MuiStack-root {
  margin-top: 3rem;
}

.img-together {
  width: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-together img{
  width: 410px;
}

.img-together p {
  text-align: justify;
  padding: 0 10px;
}

h2 {
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
}

.img-together ul li {
  list-style: circle;
  font-size: 20px;
  margin: 0 0 2rem 0;
}

.practice-features {
  margin: 0 1rem;
}

@media screen and (max-width: 1380px) {
  .MuiStack-root.three-images.css-m69qwo-MuiStack-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1320px) {
  .MuiStack-root.jumbotron-content.css-pl8gqh-MuiStack-root,
  .MuiStack-root.jumbotron-content.ss-jj2ztu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .jumbotron-image-right {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 1100px) {
  .MuiStack-root.stack.middle-stack.css-pl8gqh-MuiStack-root,
  .MuiStack-root.stack.middle-stack.css-jj2ztu {
    flex-direction: column-reverse;
  }

  .jumbotron-buttons {
    display: flex;
    flex-direction: column;
  }

  .jumbotron-buttons a {
    margin: 1rem 0;
    font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  .jumbotron {
    background-image: none;
  }

  .jumbotron-text p {
    width: 100%;
    text-align: justify;
    line-height: 2rem;
  }
}