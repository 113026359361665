@import '../../style/abstracts/_abstracts-dir';

.container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

h4, h6 {
  margin-bottom: 20px;
}

ul {
  margin: 10px 0;
  padding-left: 20px;
  list-style-type: disc;

  li {
    margin-bottom: 10px;
  }
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.bullet-list {
  margin-top: 10px;
  padding-left: 20px;
}

.bullet-list li {
  margin-bottom: 10px;
  list-style-type: disc;
  font-size: 16px;
}

.typography-body1 {
  margin-bottom: 20px;
  line-height: 2;
}

.typography-body1:last-child {
  margin-bottom: 0;
}

.typography-h6 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.typography-h4 {
  margin-bottom: 40px;
}
