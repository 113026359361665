@import '../../style/abstracts/_abstracts-dir';

:root {
    --primary-color: #48655a;
    --secondary-color: #e7e5cf;
    --tertiary-color: #42544e;
}

.coach-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: fit-content;
}

.coach {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.coach img {
    border-radius: 5px;
    width: 275px;
}

.coach h1 {
    color: var(--tertiary-color);
    font-size: 35px;
}

.coach-description {
    width: 60%;
    height: fit-content;
    border-radius: 5px;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem ;
}

.coach-description h2 {
    text-align: center;
    font-size: 30px;
    color: var(--tertiary-color);
}

.coach-description p {
    color: var(--tertiary-color);
    font-size: 20px;
}

.coach-price {
    background-color: var(--tertiary-color);
    margin: 1rem 0;
    padding: 2rem 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.coach-price .hour {
    color: var(--secondary-color);
    font-size: 5rem;
    font-weight: 600;
    margin: 0 1rem 0 0;
    text-align: center;
    width: 40%;
}

.coach-contact-info {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.coach-contact-info p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width: 1240px) {
  .coach-price .hour {
    display: flex;
    flex-direction: column;
  }

  .span-hour {
    font-weight: 100;
    font-size: 60px;
  }
}

@media screen and (max-width: 1099px) {
  .coach-price {
    flex-direction: column;
  }

  .coach-price .hour {
    border-bottom: 1px solid var(--secondary-color);
  }
}

@media screen and (max-width: 885px) {
    .coach-profile {
        flex-direction: column;
        align-items: center;
    }

    .coach-description {
        width: auto;
    }

    .coach {
        display: flex;
        flex-direction: row;
        width: fit-content;
        margin-bottom: 1rem;
    }

    .coach-info-container {
        margin: 0 2rem;
    }
}

@media screen and (max-width: 665px) {
    .coach {
        flex-direction: column;
    }
}