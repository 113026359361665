@import '../../style/abstracts/_abstracts-dir';

.page-header {
  color: #48655a;
  padding: 20px;
  background-color: #f3f4f6;
  text-align: center;

  .page-title {
    font-size: $font-size--lg;
    margin: 0 0 10px 0;
    font-weight: bold;
  }

  .header-divider {
    height: 2px;
    background-color: #ccc;
    width: 80%;
    margin: 0 auto 20px auto;
  }
}
