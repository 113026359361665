@import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,700,900&display=swap");

$quicksand: Quicksand, sans-serif;

$font-size--xl: 64px;
$font-size--lg: 32px;
$font-size--md: 16px;
$font-size--sm: 8px;

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--medium: 500;
$font-weight--semiBold: 600;
$font-weight--bold: 700;